import styled from '@emotion/styled';
import { Box } from '@chakra-ui/react';

export const MultiValueInputWrapper = styled(Box)`
  border: 1px #e0e0e0 solid;
  border-radius: 8px;
  padding: 10px 16px;

  .ReactTags__selected {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    min-height: 32px;
  }

  .ReactTags__tagInput {
    flex: 1;
    input {
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }

  .ReactTags__tag {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    background-color: #0b24fb14;
    padding: 4px 12px;
    border-radius: 8px;
    color: #0b24fb;
    font-size: 14px;
  }

  .ReactTags__remove {
    width: 24px;
    height: 24px;
    font-size: 16px;
    line-height: 16px;
  }
`;

export default MultiValueInputWrapper;
