import format from 'date-fns/format';
import * as yup from 'yup';

const BODY_SCHEMA = yup.object({
  image: yup.string().required(),
  categoryNames: yup.object(),
  isPremium: yup.object(),
  validTo: yup.object(),
});

const DEFAULT_VALUES = {
  image: '',
  categoryNames: {},
  isPremium: {},
  validTo: {},
};

const preparePayload = (values: Premium) => {
  const data = { ...values };
  const selectedCategories = values?.selectedCategories ?? [];

  Object.keys(values.categoryNames).forEach((category) => {
    const date = values?.validTo?.[category];
    const isActive = selectedCategories.includes(category);

    data.isPremium[category] = isActive;
    data.validTo[category] =
      isActive && date ? format(new Date(date), 'dd.MM.yyyy') : null;
  });

  return {
    premiums: [data],
  };
};

export { BODY_SCHEMA, DEFAULT_VALUES, preparePayload };
