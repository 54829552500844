import { Accordion, Button, Stack, Text } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import Input from 'components/Form/Input';
import { DEFAULT_VALUES } from 'pages/Configurators/Form/constants';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import Section from 'pages/Configurators/Form/components/Section';
import Card from 'components/Card';

function FormBody({ isLoading, isEditMode }: FormBodyProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sections',
    keyName: 'fieldId',
  });

  return (
    <Stack w="100%" spacing={4} mb={20}>
      {isLoading && <LoadingSpinner />}
      <Card>
        <Stack maxW={FORM_BODY_MAX_WIDTH} spacing={4}>
          <Text fontWeight={600}>
            {isEditMode ? 'Edytuj' : 'Nowy'} konfigurator
          </Text>
          <Input name="code" label="Kod" />
          <Input name="title" label="Tytuł" />
        </Stack>
      </Card>
      <Accordion allowMultiple defaultIndex={[0]}>
        <Stack w="100%" spacing={4}>
          {fields.map((field, index) => (
            <Card key={field.fieldId}>
              <Section index={index} remove={remove} />
            </Card>
          ))}
        </Stack>
      </Accordion>
      <Card>
        <Button
          variant="outlined"
          w="100%"
          onClick={() => append(DEFAULT_VALUES.sections[0])}
        >
          Dodaj sekcję
        </Button>
      </Card>
    </Stack>
  );
}

FormBody.defaultProps = {
  isEditMode: false,
};

export default FormBody;
