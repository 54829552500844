import { flexRender, Row } from '@tanstack/react-table';
import { IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useQueryClient, useMutation } from 'react-query';
import { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import Td from 'pages/Configurators/Listing/components/Table/components/Td';
import Edit from 'components/Icons/Edit';
import Delete from 'components/Icons/Delete';
import Tr from 'components/Table/Tr';
import { API, APIRoutes } from 'api';
import CustomModal from 'components/CustomModal';
import notify from 'utils/notify';
import ROUTES from 'app/routes';

interface TableRowProps {
  row: Row<Configurator>;
}

function TableRow({ row }: TableRowProps) {
  const { id = '' } = row.original;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const queryClient = useQueryClient();

  const editPath = useMemo(
    () =>
      generatePath(ROUTES.configurators.edit, {
        id,
      }),
    [id],
  );

  const { mutate: removeProductBundle } = useMutation(
    () => API.delete(APIRoutes.configurators.byId(id!)),
    {
      mutationKey: 'configuratorsMutation',
      onSuccess: () => {
        notify('success', 'Pomyślnie usunięto konfigurator');
        onClose();
        queryClient.invalidateQueries('configurators');
      },
      onError: () => {
        notify('error', 'Wystąpił problem podczas usuwania konfiguratora');
      },
    },
  );

  return (
    <Tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <>
          <CustomModal
            confirmationText="Usuń"
            onAccept={removeProductBundle}
            cancellationText="Anuluj"
            onClose={onClose}
            isOpen={isOpen}
            title="Czy na pewno chcesz trwale usunąć ten konfigurator?"
          />
          <Tooltip label="Edytuj" openDelay={500}>
            <IconButton
              aria-label="edit"
              icon={<Edit />}
              width="32px"
              height="32px"
              variant="ghost"
              as={Link}
              to={editPath}
            />
          </Tooltip>
          <Tooltip label="Usuń" openDelay={500}>
            <IconButton
              aria-label="delete"
              icon={<Delete />}
              width="32px"
              height="32px"
              variant="ghost"
              onClick={onOpen}
            />
          </Tooltip>
        </>
      </Td>
    </Tr>
  );
}

export default TableRow;
