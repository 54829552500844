import { useEffect } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { InferType } from 'yup';
import FormBody from 'pages/Configurators/Form/components/FormBody';
import {
  DEFAULT_VALUES,
  BODY_SCHEMA,
} from 'pages/Configurators/Form/constants';
import { API, APIRoutes } from 'api';
import { useFormWithSchema } from 'utils/formHooks';
import notify from 'utils/notify';
import { useGetConfigurator } from 'api/configurators';
import ROUTES from 'app/routes';
import FormFooter from 'pages/Configurators/Form/components/FormFooter';
import HeaderNavigation from 'components/HeaderNavigation';
import transformErrors from 'utils/api';

type FormData = InferType<typeof BODY_SCHEMA>;

function ConfiguratorEdit() {
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
    setError,
  } = methods;

  const { data: { data: configurator } = {}, isFetching } =
    useGetConfigurator(id);

  const { mutate: editConfigurator, isLoading: isEditing } = useMutation({
    mutationKey: 'configuratorsMutation',
    mutationFn: (data: FormData) =>
      API.put(APIRoutes.configurators.byId(id), data),
    onSuccess: () => {
      navigate(ROUTES.configurators.base);
      notify('success', 'Zmiany w konfiguratorze zostały zastosowane');
      queryClient.invalidateQueries('configurators');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      notify('error', 'Wystąpił problem podczas edycji konfiguratora');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) =>
    editConfigurator(data),
  );

  useEffect(() => {
    if (configurator) reset({ ...DEFAULT_VALUES, ...configurator });
  }, [configurator, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Konfiguratory',
          to: ROUTES.configurators.base,
        }}
        crumbs={[{ to: '', label: 'Edytuj Konfigurator' }]}
      />
      <FormProvider {...methods}>
        <VStack
          w="100%"
          h="100%"
          as="form"
          id="configurator-editor"
          onSubmit={onSubmit}
          justify="space-between"
        >
          <FormBody isLoading={isLoading} isEditMode />
          <FormFooter
            onCancel={() => navigate(ROUTES.configurators.base)}
            isLoading={isLoading}
            isDisabled={!isValid}
            formId="configurator-editor"
          />
        </VStack>
      </FormProvider>
    </Box>
  );
}

export default ConfiguratorEdit;
