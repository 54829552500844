import { DeepPartial, Theme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const styles: DeepPartial<Theme['styles']> = {
  global: (props) => ({
    body: {
      bg: mode('#ffffff', '#ffffff')(props),
    },
    ':not(.chakra-dont-set-collapse) > .chakra-collapse': {
      overflow: 'initial !important',
    },
  }),
};

export default styles;
