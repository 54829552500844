import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { InferType } from 'yup';

import FormBody from 'pages/ProductBundles/Form/BatchAdd/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/ProductBundles/Form/BatchAdd/constants';
import { API, APIRoutes } from 'api';
import { useFormWithSchema } from 'utils/formHooks';
import FormButtons from 'components/FormButtons';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import transformErrors from 'utils/api';

type FormData = InferType<typeof BODY_SCHEMA>;

function ProductBundleBatchAdd() {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
    setError,
  } = methods;

  const { mutate: createProductBundle, isLoading } = useMutation({
    mutationKey: 'productBundlesMutation',
    mutationFn: (data: FormData) =>
      API.post(APIRoutes.productBundles.batchAddProducts, data),
    onSuccess: () => {
      closeFormDrawer();
      notify(
        'success',
        'Gratulacje! Twoje dane zostały dodane pomyślnie. Nie widzisz wiersza w tabeli? Przełącz stronę by sprawdzić.',
      );
      queryClient.invalidateQueries('productBundles');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      notify('error', 'Wystąpił problem podczas uzupełniania paczek produktów');
    },
  });

  const onSubmit = handleSubmit(async (data: FormData) =>
    createProductBundle(data),
  );

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="product-bundle-batch-add"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="product-bundle-batch-add"
        />
      </DrawerFooter>
    </>
  );
}

export default ProductBundleBatchAdd;
