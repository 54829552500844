import { useIsMutating } from 'react-query';
import { Link } from 'react-router-dom';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/Configurators/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetConfigurators } from 'api/configurators';
import useQueryParams from 'utils/useQueryParams';
import Footer from 'components/Footer';
import Add from 'components/Icons/Add';
import ROUTES from 'app/routes';

function Configurators() {
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
  });

  const isMutating = useIsMutating({
    mutationKey: ['configuratorsMutation'],
  });

  const {
    isFetching,
    data: { data: configurators, totals } = { data: [], totals: 0 },
  } = useGetConfigurators(queryString);

  const showLoading = isMutating || isFetching;

  return (
    <ListingLayout
      title="Konfiguratory"
      headerButtons={[
        {
          label: 'Konfigurator',
          leftIcon: <Add />,
          as: Link,
          to: ROUTES.configurators.create,
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={configurators} />}
      <Footer totals={totals} />
    </ListingLayout>
  );
}

export default Configurators;
