import { Box } from '@chakra-ui/react';
import { Route, Outlet, Navigate } from 'react-router-dom';
import map from 'lodash/map';
import PublicLayout from 'components/Layout/PublicLayout';
import Layout from 'components/Layout';
import ROUTES from 'app/routes';

import Login from 'pages/Login';
import UsersListing from 'pages/Users/Listing';
import ProductEnhancementsListing from 'pages/ProductEnhancements/Listing';
import ProductBundlesListing from 'pages/ProductBundles/Listing';
import ProductBundleCreate from 'pages/ProductBundles/Form/Create';
import ProductBundleEdit from 'pages/ProductBundles/Form/Edit';
import ConfiguratorsListing from 'pages/Configurators/Listing';
import ConfiguratorCreate from 'pages/Configurators/Form/Create';
import ConfiguratorEdit from 'pages/Configurators/Form/Edit';
import Premiums from 'pages/Premiums/Listing';
import ElasticUtils from 'pages/ElasticUtils';

export const renderRoutes = (routes: any[]) =>
  map(routes, ({ layout: RouteLayout = Box, ...route }, idx: number) => {
    const Component =
      typeof route.element === 'string' ? route.element : <route.element />;
    return (
      <Route
        key={`route-${route.path}-${idx}`}
        path={route.path}
        element={
          <RouteLayout>
            {Component}
            <Outlet />
          </RouteLayout>
        }
      >
        {route.children ? renderRoutes(route.children) : null}
      </Route>
    );
  });

function DefaultRedirect() {
  return <Navigate to={ROUTES.auth.login} />;
}

export const PUBLIC_ROUTES = [
  { element: DefaultRedirect, path: '*', layout: PublicLayout },
  { element: Login, path: ROUTES.auth.login, layout: PublicLayout },
];

export const PRIVATE_ROUTES = [
  {
    element: UsersListing,
    path: ROUTES.users,
    layout: Layout,
  },
  {
    element: ProductEnhancementsListing,
    path: ROUTES.productEnhancements,
    layout: Layout,
  },
  {
    element: ProductBundlesListing,
    path: ROUTES.productBundles.base,
    layout: Layout,
  },
  {
    element: ProductBundleCreate,
    path: ROUTES.productBundles.create,
    layout: Layout,
  },
  {
    element: ProductBundleEdit,
    path: ROUTES.productBundles.edit,
    layout: Layout,
  },
  {
    element: ConfiguratorsListing,
    path: ROUTES.configurators.base,
    layout: Layout,
  },
  {
    element: ConfiguratorCreate,
    path: ROUTES.configurators.create,
    layout: Layout,
  },
  {
    element: ConfiguratorEdit,
    path: ROUTES.configurators.edit,
    layout: Layout,
  },
  {
    element: Premiums,
    path: ROUTES.premiums,
    layout: Layout,
  },
  {
    element: ElasticUtils,
    path: ROUTES.elasticUtils,
    layout: Layout,
  },
];
