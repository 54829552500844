import { Icon, IconProps } from '@chakra-ui/react';

function Logo(props: IconProps) {
  return (
    <Icon
      width="48px"
      height="48px"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="48" height="48" rx="8" fill="#0B24FB" />
      <g clipPath="url(#clip0_78_10073)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.4377 20.0022C29.2958 20.0022 28.7817 20.4431 28.7025 21.2307L27.6983 31.8851H23.041L23.9921 21.9382C24.3309 18.1852 26.1996 16.222 29.6006 16.0498H39.9721C39.6104 18.5366 38.2643 19.9833 36.0989 20.0022H30.4377Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36 22C35.6463 24.6953 34.3953 25.9745 32.2554 26H32.2449H29L29.3489 22H36Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.95137 29.1122C8.60767 29.1122 7.88504 28.4113 8.01495 27.0275C8.08008 26.3173 8.34578 25.837 9.50262 24.9657L20.2878 16.4902C21.1249 15.923 21.8345 16.004 22.2218 16.004C22.9965 16.004 24.0805 16.4891 23.9953 17.5868L22.6162 32H18.5379L18.808 29.1122L19.1567 25.384L19.5284 21.6201L14.6449 25.384H18.512L18.1599 29.1122H9.95137Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_78_10073">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}

export default Logo;
