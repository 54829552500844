import { Icon, IconProps } from '@chakra-ui/react';

function UploaderError(props: IconProps) {
  return (
    <Icon
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="19.5" fill="white" stroke="#EF476F" />
      <mask
        id="mask0_426_1945"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_426_1945)">
        <path
          d="M14.4 27L13 25.6L18.6 20L13 14.4L14.4 13L20 18.6L25.6 13L27 14.4L21.4 20L27 25.6L25.6 27L20 21.4L14.4 27Z"
          fill="black"
        />
      </g>
    </Icon>
  );
}

export default UploaderError;
