import { Center, Container, HStack, Text } from '@chakra-ui/react';
import Logo from 'components/Icons/Logo';

interface LayoutProps {
  children: React.ReactNode;
}

function PublicLayout({ children }: LayoutProps) {
  return (
    <Center
      w="100%"
      h="100vh"
      backgroundImage="url('/images/auth-background.jpeg')"
      backgroundPosition="center"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      _after={{
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'complementary.black',
        opacity: 0.7,
      }}
    >
      <Container
        maxWidth="lg"
        px={8}
        bgColor="white"
        padding="64px"
        borderRadius="8px"
        zIndex={1}
        margin={4}
      >
        <HStack spacing={4} width="100%" mb={8}>
          <Logo />
          <Text fontWeight={600}>FLAREON</Text>
        </HStack>
        {children}
      </Container>
    </Center>
  );
}

export default PublicLayout;
