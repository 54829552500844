import { Box, Stack } from '@chakra-ui/react';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import Input from 'components/Form/Input';
import PasswordInput from 'components/Form/PasswordInput';

function FormBody({ isLoading }: FormBodyProps) {
  return (
    <Box w="100%">
      {isLoading && <LoadingSpinner />}
      <Stack spacing={4}>
        <Input
          name="email"
          label="Adres e-mail"
          placeholder="Wpisz adres e-mail"
        />
        <PasswordInput
          name="password"
          label="Hasło"
          placeholder="Wpisz hasło"
        />
      </Stack>
    </Box>
  );
}

export default FormBody;
