import { Box, Button, Flex, HStack, Stack, Text } from '@chakra-ui/react';

interface FilterBoxProps {
  filterForm: React.ReactNode;
  filters?: React.ReactNode;
}

function FilterBox({ filterForm, filters }: FilterBoxProps) {
  return (
    <Stack spacing={4}>
      <Box bgColor="complementary.white" borderRadius="6px" padding="16px">
        <Flex gap={4} w="100%" align="baseline">
          {filterForm}
          <Button
            variant="outlined"
            type="submit"
            form="filter-form"
            flexShrink={0}
          >
            Filtruj
          </Button>
        </Flex>
      </Box>
      {filters && (
        <HStack spacing={2}>
          <Text>Wybrane filtry:</Text>
          {filters}
        </HStack>
      )}
    </Stack>
  );
}

FilterBox.defaultProps = {
  filters: null,
};

export default FilterBox;
