import { useCallback } from 'react';
import { Stack } from '@chakra-ui/react';
import Input from 'components/Form/Input';

function TagFormBody({
  prepareFieldName,
  index,
}: ConfiguratorSectionFormBodyProps) {
  const fieldName = useCallback(
    (name: string) => prepareFieldName(`tags[${index}].${name}`),
    [index, prepareFieldName],
  );

  return (
    <Stack w="100%" spacing={4}>
      <Input name={fieldName('title')} label="Tytuł" />
      <Input name={fieldName('code')} label="Kod" />
      <Input name={fieldName('description')} label="Opis" />
    </Stack>
  );
}

export default TagFormBody;
