import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';
import Card from 'components/Card';
import ListingLayout from 'components/Layout/ListingLayout';
import { useFormWithSchema } from 'utils/formHooks';
import Input from 'components/Form/Input';
import { API, APIRoutes } from 'api';
import notify from 'utils/notify';
import { ERROR_MESSAGES } from 'utils/form';

const SCHEMA = yup.object({
  sku: yup.string().required(ERROR_MESSAGES.required),
});

function ElasticUtils() {
  const methods = useFormWithSchema(SCHEMA, {
    mode: 'onChange',
    defaultValues: { sku: '' },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { mutate: removeProduct, isLoading } = useMutation({
    mutationFn: (data: { sku: string }) =>
      API.post(APIRoutes.elasticUtils.deleteBySku, data),
    onSuccess: () => {
      notify('success', 'Produkt został pomyślnie usunięty!');
    },
    onError: () => {
      notify('error', 'Wystąpił problem podczas usuwania produktu');
    },
  });

  const onSubmit = handleSubmit(async (data: { sku: string }) =>
    removeProduct(data),
  );

  return (
    <ListingLayout title="Narzędzia elastic" headerButtons={[]}>
      <Card>
        <VStack
          bgColor="complementary.superLightPurple"
          padding="32px 48px 32px 48px"
          border="1px solid"
          borderColor="complementary.outline"
          borderRadius="6px"
          spacing={6}
        >
          <Box>
            <Text variant="h2" fontWeight={600} mb={2} align="center">
              Usuń produkt według SKU
            </Text>
            <Text align="center">Dodaj pojedyncze SKU</Text>
          </Box>
          <FormProvider {...methods}>
            <VStack
              w="100%"
              h="100%"
              as="form"
              onSubmit={onSubmit}
              justify="space-between"
              spacing={8}
            >
              <Input name="sku" placeholder="SKU" />
              <Button type="submit" isDisabled={!isValid} isLoading={isLoading}>
                Usuń
              </Button>
            </VStack>
          </FormProvider>
        </VStack>
      </Card>
    </ListingLayout>
  );
}

export default ElasticUtils;
