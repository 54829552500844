import { Stack, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import isEmpty from 'lodash/isEmpty';
import ImagePreview from 'components/ImagePreview';
import { datePrettierNoTime } from 'utils/date';

function Placeholder() {
  return <Text color="complementary.placeholder">Edytuj, aby wyróżnić</Text>;
}

function GetColumns(): ColumnDef<Premium, string>[] {
  return [
    {
      header: 'Obrazek',
      accessorKey: 'image',
      cell: (info) => <ImagePreview url={info.getValue()} />,
    },
    {
      header: 'SKU',
      accessorKey: 'sku',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Wyróżniony w kategorii',
      cell: ({
        row: {
          original: { isPremium, categoryNames },
        },
      }) => (
        <Stack spacing={2}>
          {isEmpty(isPremium) ? (
            <Placeholder />
          ) : (
            Object.keys(isPremium).map((categoryId) => {
              if (!isPremium[categoryId]) return null;
              return (
                <Text key={categoryId}>
                  {categoryNames[categoryId]} ({categoryId})
                </Text>
              );
            })
          )}
        </Stack>
      ),
    },
    {
      header: 'Aktywne do',
      cell: ({
        row: {
          original: { isPremium, validTo },
        },
      }) => (
        <Stack spacing={2} align="start">
          {isEmpty(isPremium) ? (
            <Placeholder />
          ) : (
            Object.keys(isPremium).map((categoryId) => {
              if (!isPremium[categoryId]) return null;
              const date = validTo[categoryId];
              return (
                <Text key={categoryId}>
                  {date ? datePrettierNoTime(date) : '-'}
                </Text>
              );
            })
          )}
        </Stack>
      ),
    },
    {
      header: 'Akcje',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
