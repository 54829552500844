/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import { API, APIRoutes, EXTENDED_STALE_TIME } from 'api';
import { StoresResponse, SystemInfosResponse } from 'types/common';

const fetchStores = async () => {
  const { data: response } = await API.get(APIRoutes.common.stores);
  return response;
};

const fetchSystemInfos = async () => {
  const { data: response } = await API.get(APIRoutes.common.systemInfos);
  return response;
};

const useGetStores = (enabled: boolean) =>
  useQuery<StoresResponse>(['stores'], fetchStores, {
    staleTime: EXTENDED_STALE_TIME,
    enabled,
  });

const useGetSystemInfos = () =>
  useQuery<SystemInfosResponse>(['systemInfos'], fetchSystemInfos, {
    staleTime: EXTENDED_STALE_TIME,
  });

export { useGetStores, useGetSystemInfos };
