/* eslint-disable react/no-unstable-nested-components */
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { find, map } from 'lodash';
import { Virtuoso } from 'react-virtuoso';
import { Box, FormLabel, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useGetPhotoSessions } from 'api/productBundles';
import DatePicker from 'components/Form/Date';
import RadioBoxControl from 'components/Form/RadioBoxControl';

function PhotoSessionSelect() {
  const { control, setValue } = useFormContext();
  const [photoSessionId, date] = useWatch({
    control,
    name: ['photoSessionId', 'date'],
  });

  const { isFetching, data, fetchNextPage } = useGetPhotoSessions(date);

  const photoSessions = useMemo(
    () => data?.pages?.flatMap((page) => page?.data),
    [data],
  );

  const selectedSession = useMemo(
    () => find(photoSessions, { photoSessionId }),
    [photoSessionId, photoSessions],
  );

  useEffect(() => {
    if (selectedSession) {
      const skus = map(
        selectedSession?.photoSessionPositionModels,
        'productName',
      );
      setValue('name', selectedSession?.name, { shouldValidate: true });
      setValue('skus', skus, { shouldValidate: true });
    }
  }, [selectedSession, setValue]);

  return (
    <>
      <DatePicker
        label="Filtruj po dacie"
        name="date"
        placeholderText="Wybierz datę"
        showError
        fullWidth
      />
      <Box>
        <FormLabel>Wybierz sesję</FormLabel>
        <Box
          sx={{
            border: '1px #e0e0e0 solid',
            borderRadius: '8px',
            padding: '10px 16px',
            height: '350px',
          }}
        >
          <Virtuoso
            data={photoSessions}
            totalCount={photoSessions?.length || 0}
            endReached={() => fetchNextPage()}
            itemContent={(_index, session) => {
              const createdAt = new Date(session.photoSessionCreationDate);

              return (
                <RadioBoxControl
                  key={session.photoSessionId}
                  id={`photo-session-${session.photoSessionId}`}
                  name="photoSessionId"
                  label={`${session.name} - ${session.sessionType} - ${format(
                    createdAt,
                    'dd.MM.yyyy',
                  )}`}
                  value={session.photoSessionId}
                />
              );
            }}
            components={{
              EmptyPlaceholder: () =>
                isFetching ? null : <Text>Brak sesji dla wybranej daty</Text>,
              Footer: () => (isFetching ? <Text>Ładowanie...</Text> : null),
            }}
          />
        </Box>
      </Box>
    </>
  );
}

export default PhotoSessionSelect;
