import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import Close from 'components/Icons/Close';

interface FilterTagProps {
  label: string | undefined;
  onClick: () => void;
}

function FilterTag({ label, onClick }: FilterTagProps) {
  return (
    <Tag variant="solid">
      <TagLabel>{label}</TagLabel>
      <TagCloseButton as={Close} onClick={onClick} />
    </Tag>
  );
}

export default FilterTag;
