import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME } from 'api';

const fetchPremiums = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.premiums.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.total,
  };
};

const fetchPremium = async (id: string) => {
  const { data } = await API.get(APIRoutes.premiums.byId(id));
  return data;
};

const useGetPremiums = (queryString: string) =>
  useQuery<PremiumsResponse>(
    ['premiums', queryString],
    () => fetchPremiums(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetPremium = (id: string) =>
  useQuery<PremiumResponse>(['premium', id], () => fetchPremium(id));

export { useGetPremiums, useGetPremium };
