import { useMemo } from 'react';
import { Box, Stack, Text } from '@chakra-ui/react';
import qs from 'query-string';

import { useGetProductBundles } from 'api/productBundles';
import Select from 'components/Form/Select';
import TagInput from 'components/Form/TagInput';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';

function FormBody({ isLoading }: FormBodyProps) {
  const queryString = qs.stringify({
    limit: 99999,
  });

  const { isFetching, data: { data } = { data: [], totals: 0 } } =
    useGetProductBundles(`?${queryString}`);

  const productBundleOptions = useMemo(
    () =>
      data.map((productBundle) => ({
        value: productBundle.id,
        label: productBundle.name,
      })),
    [data],
  );

  const showLoading = isFetching || isLoading;

  return (
    <Box w="100%">
      {showLoading && <LoadingSpinner />}
      <Stack spacing={4}>
        <Select
          name="productBundleIds"
          label="Paczki produktów"
          placeholder="Wybierz paczki produktów"
          options={productBundleOptions}
          isMulti
          isClearable
        />
        <Text variant="h4" fontWeight={600}>
          Produkty
        </Text>
        <TagInput
          name="skus"
          label="SKU"
          placeholder="SKU (oddzielone przecinkiem)"
        />
      </Stack>
    </Box>
  );
}

export default FormBody;
