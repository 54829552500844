import { useEffect } from 'react';
import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import FormBody from 'pages/Premiums/Form/components/FormBody';
import {
  DEFAULT_VALUES,
  BODY_SCHEMA,
  preparePayload,
} from 'pages/Premiums/Form/constants';
import { API, APIRoutes } from 'api';
import FormButtons from 'components/FormButtons';
import { useFormWithSchema } from 'utils/formHooks';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import { useGetPremium } from 'api/premiums';
import parseDefaultValues from 'pages/Premiums/Form/Edit/parseDefaultValues';
import transformErrors from 'utils/api';

function PremiumEdit({ id }: { id: string }) {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
    setError,
  } = methods;

  const { data: { data: premium } = {}, isFetching } = useGetPremium(id);

  const { mutate: editPremium, isLoading: isEditing } = useMutation({
    mutationKey: 'premiumsMutation',
    mutationFn: (data: Premium) => {
      const payload = preparePayload(data);
      return API.post(APIRoutes.premiums.batchUpdate, payload);
    },
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Zmiany w produkcie zostały zastosowane');
      queryClient.invalidateQueries('premiums');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      notify('error', 'Wystąpił problem podczas edycji produktu');
    },
  });

  const onSubmit = handleSubmit(async (data: Premium) => editPremium(data));

  useEffect(() => {
    if (premium) reset({ ...DEFAULT_VALUES, ...parseDefaultValues(premium) });
  }, [premium, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="premium-editor"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="premium-editor"
        />
      </DrawerFooter>
    </>
  );
}

export default PremiumEdit;
