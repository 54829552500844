import { useIsMutating } from 'react-query';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/Users/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetUsers } from 'api/users';
import Add from 'components/Icons/Add';
import { useFormDrawer } from 'context/FormDrawerContext';
import UserCreate from 'pages/Users/Form/Create';

function Users() {
  const { setFormDrawer } = useFormDrawer();
  const isMutating = useIsMutating({
    mutationKey: ['usersMutation'],
  });

  const { isFetching, data: { data: users } = { data: [] } } = useGetUsers();

  const showLoading = isMutating || isFetching;

  return (
    <ListingLayout
      title="Użytkownicy"
      headerButtons={[
        {
          label: 'Użytkownik',
          leftIcon: <Add />,
          onClick: () =>
            setFormDrawer({
              title: 'Nowy użytkownik',
              content: <UserCreate />,
            }),
        },
      ]}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={users} />}
    </ListingLayout>
  );
}

export default Users;
