import { useCallback } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { IconButton, Tooltip } from '@chakra-ui/react';
import Td from 'pages/Premiums/Listing/components/Table/components/Td';
import Edit from 'components/Icons/Edit';
import Tr from 'components/Table/Tr';
import { useFormDrawer } from 'context/FormDrawerContext';
import PremiumEdit from 'pages/Premiums/Form/Edit';

interface TableRowProps {
  row: Row<Premium>;
}

function TableRow({ row }: TableRowProps) {
  const { id } = row.original;
  const { setFormDrawer } = useFormDrawer();

  const openEditor = useCallback(() => {
    setFormDrawer({
      title: 'Edytuj',
      content: <PremiumEdit id={id!} />,
    });
  }, [id, setFormDrawer]);

  return (
    <Tr key={`row_${id}`}>
      {row.getVisibleCells().map((cell, idx, { length }) => {
        if (idx + 1 === length) return null;
        return (
          <Td key={`row_cell_${cell.id}_${cell}`}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Td>
        );
      })}
      <Td>
        <Tooltip label="Edytuj" openDelay={500}>
          <IconButton
            aria-label="edit"
            icon={<Edit />}
            width="32px"
            height="32px"
            variant="ghost"
            onClick={openEditor}
          />
        </Tooltip>
      </Td>
    </Tr>
  );
}

export default TableRow;
