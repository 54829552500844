import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Text,
  ModalHeader,
  HStack,
} from '@chakra-ui/react';

interface CustomModalProps {
  isOpen: boolean;
  cancellationText: string;
  onClose: () => void;
  confirmationText: string;
  onAccept: () => void;
  title: string;
}

function CustomModal({
  isOpen,
  cancellationText,
  onClose,
  confirmationText,
  onAccept,
  title,
}: CustomModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <ModalHeader>
            <Text variant="h4" fontWeight={600}>
              {title}
            </Text>
          </ModalHeader>
          <HStack spacing={2} justify="center">
            <Button variant="link" onClick={onClose}>
              {cancellationText}
            </Button>
            <Button variant="solid" onClick={onAccept}>
              {confirmationText}
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CustomModal;
