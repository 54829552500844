const ROUTES = {
  auth: {
    login: '/login',
  },
  users: '/users',
  productEnhancements: '/product-enhancements',
  productBundles: {
    base: '/product-bundles',
    create: '/product-bundles/create',
    edit: '/product-bundles/:id/edit',
  },
  configurators: {
    base: '/configurators',
    create: '/configurators/create',
    edit: '/configurators/:id/edit',
  },
  premiums: '/premiums',
  elasticUtils: '/elastic-utils',
};

export const DEFAULT_ROUTE = ROUTES.productEnhancements;

export default ROUTES;
