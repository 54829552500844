import { useIsMutating } from 'react-query';
import { Link } from 'react-router-dom';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/ProductBundles/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetProductBundles } from 'api/productBundles';
import Add from 'components/Icons/Add';
import useQueryParams from 'utils/useQueryParams';
import Footer from 'components/Footer';
import { useFormDrawer } from 'context/FormDrawerContext';
import FilterBody from 'pages/ProductBundles/Listing/components/FilterBody';
import Import from 'components/Icons/Import';
import ProductBundlesImport from 'pages/ProductBundles/Form/Import';
import ProductBundleBatchAdd from 'pages/ProductBundles/Form/BatchAdd';
import ROUTES from 'app/routes';

function ProductBundles() {
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
    sort: 'createdAt:desc',
  });

  const isMutating = useIsMutating({
    mutationKey: ['productBundlesMutation'],
  });
  const { setFormDrawer } = useFormDrawer();

  const {
    isFetching,
    data: { data: productBundles, totals } = { data: [], totals: 0 },
  } = useGetProductBundles(queryString);

  const showLoading = isMutating || isFetching;

  return (
    <ListingLayout
      title="Paczki produktów"
      headerButtons={[
        {
          label: 'Paczka',
          leftIcon: <Add />,
          as: Link,
          to: ROUTES.productBundles.create,
        },
        {
          label: 'Uzupełnij',
          leftIcon: <Add />,
          variant: 'outlined',
          onClick: () =>
            setFormDrawer({
              title: 'Uzupełnij paczki produktów',
              content: <ProductBundleBatchAdd />,
            }),
        },
        {
          label: 'Importuj',
          leftIcon: <Import />,
          variant: 'link',
          onClick: () =>
            setFormDrawer({
              title: 'Importuj paczki produktów',
              content: <ProductBundlesImport />,
            }),
        },
      ]}
      filterBody={<FilterBody />}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={productBundles} />}
      <Footer totals={totals} />
    </ListingLayout>
  );
}

export default ProductBundles;
