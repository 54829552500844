import { useState } from 'react';
import { Accordion } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import ExpandableNavItem from 'components/Navigation/ExpandableItem';
import NavItem from 'components/Navigation/Item';
import ROUTES from 'app/routes';
import Users from 'components/Icons/Users';
import Menu from 'components/Icons/Menu';
import Tool from 'components/Icons/Tool';
import { ExpandableNavOption, NavOption } from 'types/navigation';
import useQueryParams from 'utils/useQueryParams';

const NAV_OPTIONS: Array<NavOption | ExpandableNavOption> = [
  {
    title: 'Narzędzia produktowe',
    icon: Menu,
    options: [
      { title: 'Wzbogacenia produktów', link: ROUTES.productEnhancements },
      { title: 'Konfiguratory', link: ROUTES.configurators.base },
      { title: 'Premium', link: ROUTES.premiums },
      { title: 'Paczki produktów', link: ROUTES.productBundles.base },
    ],
  },
  {
    title: 'Narzędzia elastic',
    icon: Tool,
    link: ROUTES.elasticUtils,
  },
  {
    title: 'Użytkownicy',
    icon: Users,
    link: ROUTES.users,
  },
];

function Navigation() {
  const { queryString } = useQueryParams();
  const { pathname } = useLocation();
  const [index, setIndex] = useState<number | number[]>(-1);

  return (
    <Accordion
      allowToggle
      h="100%"
      w="100%"
      overflowX="hidden"
      overflowY="auto"
      index={index}
      onChange={(element) => setIndex(element)}
    >
      {NAV_OPTIONS.map((option) => {
        if ('link' in option) {
          const link = option.link === pathname ? queryString : option.link;

          return (
            <NavItem
              key={`nav_option_${option.title}`}
              link={link}
              title={option.title}
              icon={<option.icon />}
            />
          );
        }
        return (
          <ExpandableNavItem
            key={`nav_option_${option.title}`}
            options={option.options}
            title={option.title}
            icon={<option.icon />}
          />
        );
      })}
    </Accordion>
  );
}

export default Navigation;
