import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

const BODY_SCHEMA = yup.object({
  productBundleIds: yup
    .array()
    .of(yup.string())
    .min(1, ERROR_MESSAGES.required),
  skus: yup.array().of(yup.string()).min(1, ERROR_MESSAGES.required),
});

const DEFAULT_VALUES = {
  productBundleIds: [],
  skus: [],
};

export { BODY_SCHEMA, DEFAULT_VALUES };
