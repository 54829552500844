import * as yup from 'yup';
import { FormProvider } from 'react-hook-form';
import useQueryParams from 'utils/useQueryParams';
import { useFormWithSchema } from 'utils/formHooks';
import Input from 'components/Form/Input';
import { PAGE, SKU } from 'pages/ProductEnhancements/Listing/constants';
import FilterBox from 'components/FilterBox';
import FilterTag from 'components/FilterTag';

const schema = yup.object({
  sku: yup.string(),
});

function FilterBody() {
  const { search, setSearch } = useQueryParams();

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues: {
      sku: search.get(SKU) || '',
    },
  });

  const { handleSubmit, getValues, setValue } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { sku } = data;

    if (sku) search.set(SKU, sku);
    else search.delete(SKU);

    search.set(PAGE, '1');
    setSearch(search);
  });

  const sku = getValues(SKU);

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} id="filter-form">
        <FilterBox
          filterForm={<Input name={SKU} placeholder="SKU" />}
          filters={
            sku ? (
              <FilterTag
                label={sku}
                onClick={() => {
                  setValue(SKU, '');
                  search.delete(SKU);
                  setSearch(search);
                }}
              />
            ) : null
          }
        />
      </form>
    </FormProvider>
  );
}

export default FilterBody;
