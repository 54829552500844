import { Button, HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Delete from 'components/Icons/Delete';
import { OptionType } from 'types/common';

interface FieldArraysSectionProps {
  sectionTitle: string;
  fieldName: string;
  prepareFieldName: (name: string) => string;
  defaultValue: any;
  formBody: (props: ConfiguratorSectionFormBodyProps) => JSX.Element;
  itemName: string;
  availableTags?: OptionType[];
}

function FieldsArraySection({
  sectionTitle,
  fieldName,
  prepareFieldName,
  defaultValue,
  formBody,
  itemName,
  availableTags = [],
}: FieldArraysSectionProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: prepareFieldName(fieldName),
    keyName: 'fieldId',
  });

  const FormBody = formBody;

  return (
    <>
      <Text variant="h4" fontWeight={600}>
        {sectionTitle}
      </Text>
      {fields.map((field, fieldIndex) => (
        <Stack spacing={4} key={field.fieldId}>
          <HStack
            justify="space-between"
            spacing={2}
            bgColor="complementary.superLightPurple"
            padding="8px 16px"
            borderRadius="8px"
          >
            <Text variant="h4" fontWeight={600}>
              {fieldIndex + 1} {itemName}
            </Text>
            <IconButton
              variant="unstyled"
              icon={<Delete />}
              onClick={() => remove(fieldIndex)}
              aria-label="remove"
              minWidth="24px"
              height="24px"
            />
          </HStack>
          <FormBody
            prepareFieldName={prepareFieldName}
            index={fieldIndex}
            availableTags={availableTags}
          />
        </Stack>
      ))}
      <HStack justify="space-between" spacing={2}>
        <Text variant="h4" fontWeight={600}>
          {itemName}
        </Text>
        <Button variant="link" onClick={() => append(defaultValue)} padding={0}>
          Dodaj
        </Button>
      </HStack>
    </>
  );
}

FieldsArraySection.defaultProps = {
  availableTags: [],
};

export default FieldsArraySection;
