import { Box, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import FormBody from 'pages/ProductBundles/Form/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/ProductBundles/Form/constants';
import { API, APIRoutes } from 'api';
import { useFormWithSchema } from 'utils/formHooks';
import notify from 'utils/notify';
import transformErrors from 'utils/api';
import HeaderNavigation from 'components/HeaderNavigation';
import ROUTES from 'app/routes';
import FormFooter from 'pages/Configurators/Form/components/FormFooter';

function ProductBundleCreate() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
    setError,
  } = methods;

  const { mutate: createProductBundle, isLoading } = useMutation({
    mutationKey: 'productBundlesMutation',
    mutationFn: (data: ProductBundle) =>
      API.post(APIRoutes.productBundles.index(), data),
    onSuccess: () => {
      navigate(ROUTES.productBundles.base);
      notify(
        'success',
        'Gratulacje! Twoje dane zostały dodane pomyślnie. Nie widzisz wiersza w tabeli? Przełącz stronę by sprawdzić.',
      );
      queryClient.invalidateQueries('productBundles');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      notify('error', 'Wystąpił problem podczas tworzenia paczki produktów');
    },
  });

  const onSubmit = handleSubmit(async (data: ProductBundle) =>
    createProductBundle(data),
  );

  return (
    <Box>
      <HeaderNavigation
        baseCrumb={{
          label: 'Paczki produktów',
          to: ROUTES.productBundles.base,
        }}
        crumbs={[{ to: '', label: 'Nowa paczka' }]}
      />
      <FormProvider {...methods}>
        <VStack
          w="100%"
          h="100%"
          as="form"
          id="product-bundle-creator"
          onSubmit={onSubmit}
          justify="space-between"
        >
          <FormBody isLoading={isLoading} />
          <FormFooter
            onCancel={() => navigate(ROUTES.productBundles.base)}
            isLoading={isLoading}
            isDisabled={!isValid}
            formId="product-bundle-creator"
          />
        </VStack>
      </FormProvider>
    </Box>
  );
}

export default ProductBundleCreate;
