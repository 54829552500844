import { useIsMutating } from 'react-query';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/Premiums/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetPremiums } from 'api/premiums';
import useQueryParams from 'utils/useQueryParams';
import Footer from 'components/Footer';
import FilterBody from 'pages/Premiums/Listing/components/FilterBody';

function Premiums() {
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
  });

  const isMutating = useIsMutating({
    mutationKey: ['premiumsMutation'],
  });

  const {
    isFetching,
    data: { data: premiums, totals } = { data: [], totals: 0 },
  } = useGetPremiums(queryString);

  const showLoading = isMutating || isFetching;

  return (
    <ListingLayout
      title="Premium"
      headerButtons={[]}
      filterBody={<FilterBody />}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={premiums} />}
      <Footer totals={totals} />
    </ListingLayout>
  );
}

export default Premiums;
