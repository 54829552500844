/* eslint-disable no-console */

export const ERROR_DICT: { [key: string]: string } = {
  sku_taken: 'Wpisane SKU jest zajęte',
  sku_not_empty: 'SKU nie może być puste',
  user_exists: 'Użytkownik już istnieje',
};

const transformErrors: (errors: { [key: string]: string[] }) => {
  [key: string]: string;
} = (errors) => {
  try {
    if (errors) {
      const err: { [key: string]: string } = {};

      Object.keys(errors).forEach((field: string) => {
        const error = errors[field][0];
        const errorCode = error?.split('.')?.[1];
        err[field] = ERROR_DICT[errorCode || error] ?? error;
      });

      return err;
    }
  } catch (error) {
    console.log(error);
  }

  return {};
};

export default transformErrors;
