/* eslint-disable @typescript-eslint/dot-notation */
import axios from 'axios';
import { Query } from 'react-query';

const BASE_API_URL = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: `${BASE_API_URL}/api/v2`,
});

const setAuthHeader = (token: string) => {
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  API.defaults.headers.common['auth-token'] = token;
};

const setStoreHeader = (store: string) => {
  API.defaults.headers.common['Store'] = store;
};

const destroySession: () => void = () => {
  delete API.defaults.headers.common['Authorization'];
  delete API.defaults.headers.common['auth-token'];
  delete API.defaults.headers.common['Store'];
};

const APIRoutes = {
  auth: {
    signIn: '/auth/sign_in',
    currentUser: '/auth/profile',
  },
  users: {
    index: '/users',
    byId: (id: string) => `/users/${id}`,
  },
  productEnhancements: {
    index: (queryString?: string) =>
      `/product_enhancements${queryString ?? ''}`,
    byId: (id: string) => `/product_enhancements/${id}`,
  },
  productBundles: {
    index: (queryString?: string) => `/product_bundles${queryString ?? ''}`,
    byId: (id: string) => `/product_bundles/${id}`,
    import: '/product_bundles/csv',
    photoSessions: (queryString: string) =>
      `/photosessions?${queryString ?? ''}`,
    batchAddProducts: '/product_bundles/batch-add-skus',
  },
  configurators: {
    index: (queryString?: string) => `/configurators${queryString ?? ''}`,
    byId: (id: string) => `/configurators/${id}`,
    autocomplete: (sku: string) => `/configurators/autocomplete?sku=${sku}`,
  },
  premiums: {
    index: (queryString?: string) => `/premiums${queryString ?? ''}`,
    byId: (id: string) => `/premiums/${id}`,
    batchUpdate: '/premiums/batch_update',
  },
  elasticUtils: {
    deleteBySku: '/elastic_utils/delete_by_sku',
  },
  common: {
    stores: '/stores',
    systemInfos: '/system_infos',
  },
  products: (queryString?: string) => `/products?${queryString ?? ''}`,
};

const DEFAULT_STALE_TIME = 10 * 1000;
const EXTENDED_STALE_TIME = 60 * 1000;

const predicate = (query: Query) => query.queryKey[0] !== 'stores';

export {
  BASE_API_URL,
  API,
  APIRoutes,
  setAuthHeader,
  setStoreHeader,
  destroySession,
  DEFAULT_STALE_TIME,
  EXTENDED_STALE_TIME,
  predicate,
};
