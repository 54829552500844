import { useMemo } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useAuth } from 'context/AuthContext';
import SingleSelect from 'components/Form/Select/SingleSelect';
import { useGetStores } from 'api/common';
import { LABEL_DICT } from 'components/StoreChangeModal/dictionary';

function StoreSelect() {
  const { store, changeStore } = useAuth();
  const { isFetching, data: { data: stores } = { data: [] } } =
    useGetStores(true);

  const options = useMemo(
    () =>
      stores?.map((item) => ({
        label: LABEL_DICT[item.store],
        value: item.store,
      })),
    [stores],
  );

  return (
    <FormControl>
      <FormLabel>Wybierz maskę</FormLabel>
      <SingleSelect
        instanceId="mask"
        placeholder="Wybierz kraj, w którym chcesz pracować"
        value={options.find((option) => option.value === store)}
        onChange={(newValue: any) => changeStore(newValue.value)}
        options={options}
        isLoading={isFetching}
      />
    </FormControl>
  );
}

export default StoreSelect;
