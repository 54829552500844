import { Box, Divider, HStack, Stack, Text } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useMemo } from 'react';
import { isEmpty, pull } from 'lodash';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import ImagePreview from 'components/ImagePreview';
import Select from 'components/Form/Select';
import FilterTag from 'components/FilterTag';
import DatePicker from 'components/Form/Date';
import Placeholder from 'components/Placeholder';
import FeatureSearch from 'components/Icons/FeatureSearch';

function FormBody({ isLoading }: FormBodyProps) {
  const { control, getValues, setValue } = useFormContext();
  const [sku, image, categoryNames] = getValues([
    'sku',
    'image',
    'categoryNames',
  ]);
  const selectedCategories: string[] = useWatch({
    control,
    name: 'selectedCategories',
  });

  const options = useMemo(
    () =>
      Object.keys(categoryNames)?.map((category) => ({
        label: `${categoryNames[category]} (${category})`,
        value: category,
      })) ?? [],
    [categoryNames],
  );

  return (
    <Box w="100%">
      {isLoading && <LoadingSpinner />}
      <Stack spacing={4}>
        <Stack spacing={2}>
          <Box w="64px">
            <ImagePreview url={image} />
          </Box>
          <Text>{sku}</Text>
        </Stack>
        <Select
          name="selectedCategories"
          label="Kategorie"
          placeholder="Wybierz z listy"
          options={options}
          controlShouldRenderValue={false}
          isMulti
        />
        <Text variant="h4" fontWeight={600}>
          Wyróżnione w kategorii
        </Text>
        {isEmpty(selectedCategories) ? (
          <Placeholder
            icon={<FeatureSearch />}
            title="Brak wyróżnionych w kategorii"
            subtitle="Wybierz, w których kategoriach chcesz wyróżnić produkt klikając w listę powyżej"
          />
        ) : (
          <Stack spacing={2} divider={<Divider />}>
            {selectedCategories?.map((category) => (
              <HStack key={category} spacing={2} justify="space-between">
                <FilterTag
                  label={`${categoryNames[category]} (${category})`}
                  onClick={() =>
                    setValue(
                      'selectedCategories',
                      pull(selectedCategories, category),
                    )
                  }
                />
                <DatePicker
                  name={`validTo.${category}`}
                  placeholderText="Aktywne do"
                  showError
                />
              </HStack>
            ))}
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

export default FormBody;
