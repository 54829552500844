import { useInfiniteQuery, useQuery } from 'react-query';
import qs from 'query-string';
import { format } from 'date-fns';
import { API, APIRoutes, BASE_API_URL, DEFAULT_STALE_TIME } from 'api';

const fetchProductBundles = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.productBundles.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.total,
  };
};

const fetchProductBundle = async (id: string) => {
  const { data } = await API.get(APIRoutes.productBundles.byId(id));
  return data;
};

const fetchPhotoSessions = async (date: Date, page: number) => {
  const queryString = qs.stringify({
    PageSize: 50,
    Date: date ? format(date, 'yyyy-MM-dd') : undefined,
    PageNumber: page,
  });

  const { data } = await API.get(
    `${BASE_API_URL}${APIRoutes.productBundles.photoSessions(queryString)}`,
  );
  return data;
};

const useGetProductBundles = (queryString: string) =>
  useQuery<ProductBundlesReponse>(
    ['productBundles', queryString],
    () => fetchProductBundles(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetProductBundle = (id: string) =>
  useQuery<ProductBundleResponse>(['productBundle', id], () =>
    fetchProductBundle(id),
  );

const useGetPhotoSessions = (date: Date) =>
  useInfiniteQuery({
    queryKey: ['photoSessions', date],
    queryFn: ({ pageParam = 1 }) => fetchPhotoSessions(date, pageParam),
    staleTime: Infinity,
    getNextPageParam: (lastPage, pages) =>
      lastPage.data.length ? pages.length + 1 : undefined,
  });

export { useGetProductBundles, useGetProductBundle, useGetPhotoSessions };
