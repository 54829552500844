import { useCallback } from 'react';
import { Box, Icon, IconButton } from '@chakra-ui/react';
import useQueryParams from 'utils/useQueryParams';
import { ASC, DESC, SORT } from 'components/Table/HeaderCell/constants';
import ArrowUp from 'components/Icons/ArrowUp';
import ArrowDown from 'components/Icons/ArrowDown';

type HeaderCellProps = {
  sortKey: string;
  children: React.ReactNode;
};

function HeaderCell({ sortKey, children }: HeaderCellProps) {
  const { search, setSearch } = useQueryParams();

  const [, order] = search.get(SORT)?.split(':') || ['', ''];
  const isAsc = order === ASC;

  const onClick = useCallback(() => {
    search.set(SORT, `${sortKey}:${isAsc ? DESC : ASC}`);
    search.set('page', '1');
    setSearch(search);
  }, [isAsc, search, setSearch, sortKey]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {children}
      <IconButton
        variant="ghost"
        aria-label="toggle sort order"
        onClick={onClick}
      >
        <Icon
          as={isAsc ? ArrowUp : ArrowDown}
          sx={{
            width: '20px',
            height: '20px',
            strokeWidth: 1.5,
            fill: 'none',
          }}
        />
      </IconButton>
    </Box>
  );
}

export default HeaderCell;
