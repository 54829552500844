const parseDefaultValues = (premium: Premium) => {
  const result: Record<string, any> = { ...premium };

  Object.keys(premium.validTo).forEach((category) => {
    const date = premium.validTo[category];
    result.validTo[category] = date ? new Date(date) : null;
  });

  result.selectedCategories = Object.keys(premium.isPremium).filter(
    (category) => premium.isPremium[category],
  );

  return result;
};

export default parseDefaultValues;
