import { Text, HStack, StackDivider, Spinner } from '@chakra-ui/react';
import { useGetSystemInfos } from 'api/common';
import { datePrettier } from 'utils/date';
import Info from 'components/Icons/Info';

function SystemInfosBox() {
  const { isFetching, data: { data: systemInfos } = {} } = useGetSystemInfos();

  return (
    <HStack
      h="100%"
      bgColor="complementary.lightBlue"
      padding="8px 32px 8px 16px"
      borderRadius="8px"
      spacing={2}
    >
      {isFetching ? (
        <Spinner marginX="auto" />
      ) : (
        <>
          <Info />
          <HStack
            spacing={2}
            divider={<StackDivider borderColor="complementary.richBlack" />}
            fontSize="14px"
          >
            <Text>
              Stan joba importującego produkty:
              <Text as="span" fontWeight={600} ml={2}>
                {systemInfos?.productsIndexJobStatus || '-'}
              </Text>
            </Text>
            <Text>
              Data uruchomienia ostatniego indeksu:
              <Text as="span" fontWeight={600} ml={2}>
                {systemInfos?.lastProductsIndexStartDate
                  ? datePrettier(systemInfos?.lastProductsIndexStartDate)
                  : '-'}
              </Text>
            </Text>
            <Text>
              Data ostatniego swapa indeksu:
              <Text as="span" fontWeight={600} ml={2}>
                {systemInfos?.lastProductsIndexSwapDate
                  ? datePrettier(systemInfos?.lastProductsIndexSwapDate)
                  : '-'}
              </Text>
            </Text>
          </HStack>
        </>
      )}
    </HStack>
  );
}
export default SystemInfosBox;
