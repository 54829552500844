import { Icon, IconProps } from '@chakra-ui/react';

function ArrowUp(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_742_19747"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_742_19747)">
        <path
          d="M11.25 19.5V7.373L5.55375 13.0693L4.5 12L12 4.5L19.5 12L18.4462 13.0693L12.75 7.373V19.5H11.25Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default ArrowUp;
