import { Icon, IconProps } from '@chakra-ui/react';

function FileUploader(props: IconProps) {
  return (
    <Icon
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="19.5" fill="white" stroke="#EBEDFB" />
      <mask
        id="mask0_426_1169"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_426_1169)">
        <path
          d="M26 28.6441L29.6442 24.9999L28.6 23.9557L26.75 25.8058V21.275H25.25V25.8058L23.4 23.9557L22.3558 24.9999L26 28.6441ZM22.25 31.7499V30.25H29.75V31.7499H22.25ZM14.0577 27.75C13.5654 27.75 13.141 27.5718 12.7846 27.2154C12.4282 26.8589 12.25 26.4346 12.25 25.9423V12.0577C12.25 11.5654 12.4282 11.141 12.7846 10.7846C13.141 10.4282 13.5654 10.25 14.0577 10.25H21L26.75 15.9999V19.0826H25.25V16.7499H20.25V11.7499H14.0577C13.9807 11.7499 13.9102 11.782 13.8461 11.8461C13.782 11.9102 13.7499 11.9807 13.7499 12.0576V25.9423C13.7499 26.0192 13.782 26.0897 13.8461 26.1538C13.9102 26.2179 13.9807 26.25 14.0577 26.25H20.0577V27.7499L14.0577 27.75Z"
          fill="black"
        />
      </g>
    </Icon>
  );
}

export default FileUploader;
