import { Box, Stack } from '@chakra-ui/react';
import LoadingSpinner from 'components/LoadingSpinner';
import { FormBodyProps } from 'types/common';
import Input from 'components/Form/Input';

function FormBody({ isLoading }: FormBodyProps) {
  return (
    <Box w="100%">
      {isLoading && <LoadingSpinner />}
      <Stack spacing={4}>
        <Input name="sku" label="SKU" placeholder="Wpisz SKU produktu" />
        <Input name="arLink" label="Link AR" placeholder="Wpisz link" />
        <Input name="threeDLink" label="Link 3D" placeholder="Wpisz link" />
        <Input
          name="changingRoomLink"
          label="Link do e-przymierzalni"
          placeholder="Wpisz link"
        />
        <Input
          name="webmLink"
          label="Link do filmu webm"
          placeholder="Wpisz link"
        />
        <Input
          name="mp4Link"
          label="Link do filmu MP4"
          placeholder="Wpisz link"
        />
      </Stack>
    </Box>
  );
}

export default FormBody;
