import * as yup from 'yup';

const BODY_SCHEMA = yup.object({
  code: yup.string(),
  title: yup.string(),
  sections: yup.array().of(
    yup.object().shape({
      title: yup.string(),
      tags: yup.array().of(
        yup.object().shape({
          title: yup.string(),
          code: yup.string(),
          description: yup.string(),
        }),
      ),
      products: yup.array().of(
        yup.object().shape({
          title: yup.string(),
          sku: yup.string(),
          parentSku: yup.string(),
          price: yup
            .mixed()
            .transform((value) => Number(value) ?? 0)
            .nullable(),
          omnibusPrice: yup
            .mixed()
            .transform((value) => Number(value) ?? 0)
            .nullable(),
          minimalPrice: yup.mixed().transform((value) => Number(value) ?? 0),
          currency: yup.string(),
          imageUrl: yup.string(),
          tag: yup.array().of(yup.string()),
        }),
      ),
    }),
  ),
});

const DEFAULT_VALUES = {
  code: '',
  title: '',
  sections: [
    {
      title: '',
      tags: [{ title: '', code: '', description: '' }],
      products: [
        {
          title: '',
          sku: '',
          parentSku: '',
          price: 0,
          omnibusPrice: 0,
          minimalPrice: 0,
          currency: '',
          imageUrl: '',
          tag: [],
        },
      ],
    },
  ],
};

const CODE_OPTIONS = [
  {
    label: 'Ramka',
    value: 'frame',
  },
  {
    label: 'Soczewka',
    value: 'lens',
  },
];

export { BODY_SCHEMA, DEFAULT_VALUES, CODE_OPTIONS };
