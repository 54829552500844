import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from '@emotion/styled';
import { HStack, Text, Image, Spinner, Stack, Center } from '@chakra-ui/react';
import { useGetProducts } from 'api/products';

export const StyledSlide = styled(SwiperSlide)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 0;

  transition: all 0.25s;
  transition-timing-function: ease-in-out;

  width: 100%;
  max-width: 223px;
`;

interface ProductsPreviewProps {
  skus: string[];
}

function ProductsPreview({ skus }: ProductsPreviewProps) {
  const { isFetching, data: { items } = { data: [] } } = useGetProducts(skus);

  return (
    <Stack spacing={4}>
      {isFetching ? (
        <Center height="420px">
          <Spinner />
        </Center>
      ) : (
        <Swiper
          spaceBetween={8}
          slidesPerView="auto"
          style={{
            width: '100%',
          }}
        >
          {items?.map((product, idx) => {
            const minimalPrice = product.price.minimalPrice.amount;
            const regularPrice = product.price.regularPrice.amount;
            const omnibusPrice = product.priceRange.omnibusPrice.finalPrice;
            const hasDiscount = regularPrice.value < minimalPrice.value;

            return (
              <StyledSlide key={`product_image${+idx}`}>
                <Image
                  src={product.image.url}
                  alt={`Zdjęcie produktu ${idx}`}
                  bgColor="complementary.gray"
                  width="223px"
                  height="300px"
                />
                <Text fontSize="14px" lineHeight="24px" my={1} w="100%">
                  {product.name}
                </Text>
                <HStack spacing={1} w="100%">
                  <Text
                    fontWeight={hasDiscount ? 400 : 600}
                    fontSize="16px"
                    lineHeight="24px"
                    textDecoration={hasDiscount ? 'line-through' : 'none'}
                  >
                    {regularPrice.value} {regularPrice.currency}
                  </Text>
                  {hasDiscount && (
                    <Text
                      fontWeight={600}
                      fontSize="16px"
                      lineHeight="24px"
                      color="complementary.error"
                    >
                      {minimalPrice.value} {minimalPrice.currency}
                    </Text>
                  )}
                </HStack>
                {omnibusPrice.value && (
                  <Text fontSize="10px" lineHeight="16px">
                    Najniższa cena z ostatnich 30 dni przed obniżką:{' '}
                    {omnibusPrice.value} {omnibusPrice.currency}
                  </Text>
                )}
                <Text
                  fontSize="12px"
                  fontWeight={600}
                  px={1}
                  position="absolute"
                  top="0"
                  left="0"
                  transform="rotate(-90deg) translateX(-100%)"
                  transformOrigin="left top"
                  background="white"
                >
                  Nowość
                </Text>
              </StyledSlide>
            );
          })}
        </Swiper>
      )}
    </Stack>
  );
}

export default ProductsPreview;
