import { ColumnDef } from '@tanstack/react-table';

function GetColumns(): ColumnDef<ProductEnhancement, string>[] {
  return [
    {
      header: 'SKU',
      accessorKey: 'sku',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Akcje',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
