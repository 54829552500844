import { Icon, IconProps } from '@chakra-ui/react';

function ArrowDown(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_742_19748"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_742_19748)">
        <path
          d="M11.25 4.5V16.627L5.55375 10.9307L4.5 12L12 19.5L19.5 12L18.4462 10.9307L12.75 16.627V4.5H11.25Z"
          fill="black"
        />
      </g>
    </Icon>
  );
}

export default ArrowDown;
