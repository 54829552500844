import {
  Text,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import StoreButton from 'components/StoreChangeModal/StoreButton';
import { useGetStores } from 'api/common';
import LoadingSpinner from 'components/LoadingSpinner';

interface StoreChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentStore: string;
}

function StoreChangeModal({
  isOpen,
  onClose,
  currentStore,
}: StoreChangeModalProps) {
  const { isFetching, data: { data: stores } = { data: [] } } =
    useGetStores(isOpen);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent p={0}>
        <ModalBody>
          <ModalHeader>
            <Text variant="h4" fontWeight={600}>
              Wybierz kraj aplikacji, w której chcesz pracować
            </Text>
          </ModalHeader>
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            <Flex
              wrap="wrap"
              maxH="336px"
              direction="column"
              w="100%"
              columnGap={6}
              alignItems="center"
            >
              {stores.map((store) => (
                <StoreButton
                  key={`country_button_${store.store}`}
                  store={store.store}
                  closeModal={onClose}
                  isChecked={store.store === currentStore}
                />
              ))}
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default StoreChangeModal;
