import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME } from 'api';

const fetchUsers = async () => {
  const { data: response } = await API.get(APIRoutes.users.index);
  return {
    data: response?.data,
    totals: response?.meta?.total,
  };
};

const fetchUser = async (id: string) => {
  const { data } = await API.get(APIRoutes.users.byId(id));
  return data;
};

const useGetUsers = () =>
  useQuery<UsersResponse>(['users'], fetchUsers, {
    staleTime: DEFAULT_STALE_TIME,
  });

const useGetUser = (id: string) =>
  useQuery<UserResponse>(['user', id], () => fetchUser(id));

export { useGetUsers, useGetUser };
