import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  HStack,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { slice, flatMap, filter } from 'lodash';
import Input from 'components/Form/Input';
import Delete from 'components/Icons/Delete';
import Textarea from 'components/Form/Textarea';
import FieldsArraySection from 'pages/Configurators/Form/components/FieldsArraySection';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import {
  CODE_OPTIONS,
  DEFAULT_VALUES,
} from 'pages/Configurators/Form/constants';
import TagFormBody from 'pages/Configurators/Form/components/TagFormBody';
import ProductFormBody from 'pages/Configurators/Form/components/ProductFormBody';
import RadioBoxControl from 'components/Form/RadioBoxControl';

interface SectionProps {
  index: number;
  remove: (index: number) => void;
}

function Section({ index, remove }: SectionProps) {
  const { control } = useFormContext();
  const sections = useWatch({
    control,
    name: 'sections',
  });

  const availableTags = useMemo(() => {
    const prevSections = slice(sections, 0, index + 1);
    const tags = filter(
      flatMap(prevSections, 'tags'),
      (tag) => tag?.title && tag?.code,
    );

    return tags.map((tag) => ({
      label: tag.title,
      value: tag.code,
    }));
  }, [index, sections]);

  const prepareFieldName = useCallback(
    (name: string) => `sections[${index}].${name}`,
    [index],
  );

  return (
    <AccordionItem>
      <AccordionButton as={Box}>
        <HStack w="100%" spacing={2}>
          <Text variant="h4" fontWeight={600} w="100%" align="left">
            {index + 1} Sekcja
          </Text>
          <IconButton
            variant="unstyled"
            icon={<AccordionIcon fontSize="24px" />}
            aria-label="toggle"
            minWidth="24px"
            height="24px"
          />
          <IconButton
            variant="unstyled"
            icon={<Delete />}
            onClick={() => remove(index)}
            aria-label="remove"
            minWidth="24px"
            height="24px"
          />
        </HStack>
      </AccordionButton>
      <AccordionPanel>
        <Stack spacing={4} maxW={FORM_BODY_MAX_WIDTH}>
          <Input name={prepareFieldName('name')} label="Nazwa" />
          <Input name={prepareFieldName('title')} label="Tytuł" />
          <Textarea name={prepareFieldName('description')} label="Opis" />
          <Text variant="h4" fontWeight={600}>
            Kod
          </Text>
          <HStack spacing={2} wrap="wrap">
            {CODE_OPTIONS.map((option) => (
              <RadioBoxControl
                id={prepareFieldName(`code-option-${option.value}`)}
                key={option.value}
                label={option.label}
                name={prepareFieldName('code')}
                value={option.value}
              />
            ))}
          </HStack>
          <Divider />
          <FieldsArraySection
            sectionTitle="Tagi produktowe"
            fieldName="tags"
            prepareFieldName={prepareFieldName}
            defaultValue={DEFAULT_VALUES.sections[0].tags[0]}
            formBody={TagFormBody}
            itemName="Tag"
          />
          <Divider />
          <FieldsArraySection
            sectionTitle="Produkty"
            fieldName="products"
            prepareFieldName={prepareFieldName}
            defaultValue={DEFAULT_VALUES.sections[0].products[0]}
            formBody={ProductFormBody}
            itemName="Produkt"
            availableTags={availableTags}
          />
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
}

export default Section;
