import { useIsMutating } from 'react-query';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/ProductEnhancements/Listing/components/Table';
import LoadingIndicator from 'components/LoadingIndicator';
import { useGetProductEnhancements } from 'api/productEnhancements';
import Add from 'components/Icons/Add';
import useQueryParams from 'utils/useQueryParams';
import Footer from 'components/Footer';
import { useFormDrawer } from 'context/FormDrawerContext';
import ProductEnhancementCreate from 'pages/ProductEnhancements/Form/Create';
import FilterBody from 'pages/ProductEnhancements/Listing/components/FilterBody';

function ProductEnhancements() {
  const { queryString } = useQueryParams({
    limit: '10',
    page: '1',
  });

  const isMutating = useIsMutating({
    mutationKey: ['productEnhancementsMutation'],
  });
  const { setFormDrawer } = useFormDrawer();

  const {
    isFetching,
    data: { data: productEnhancements, totals } = { data: [], totals: 0 },
  } = useGetProductEnhancements(queryString);

  const showLoading = isMutating || isFetching;

  return (
    <ListingLayout
      title="Wzbogacenia produktów"
      headerButtons={[
        {
          label: 'Wzbogacenie produktu',
          leftIcon: <Add />,
          onClick: () =>
            setFormDrawer({
              title: 'Nowe wzbogacenie produktu',
              content: <ProductEnhancementCreate />,
            }),
        },
      ]}
      filterBody={<FilterBody />}
      showSystemInfos
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && <Table data={productEnhancements} />}
      <Footer totals={totals} />
    </ListingLayout>
  );
}

export default ProductEnhancements;
