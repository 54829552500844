import { useEffect } from 'react';
import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import FormBody from 'pages/ProductEnhancements/Form/components/FormBody';
import {
  DEFAULT_VALUES,
  BODY_SCHEMA,
} from 'pages/ProductEnhancements/Form/constants';
import { API, APIRoutes } from 'api';
import { useGetProductEnhancement } from 'api/productEnhancements';
import FormButtons from 'components/FormButtons';
import { useFormWithSchema } from 'utils/formHooks';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import transformErrors from 'utils/api';

function ProductEnhancementEdit({ id }: { id: string }) {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
    setError,
  } = methods;

  const { data: { data: productEnhancement } = {}, isFetching } =
    useGetProductEnhancement(id);

  const { mutate: editProductEnhancement, isLoading: isEditing } = useMutation({
    mutationKey: 'productEnhancementsMutation',
    mutationFn: (data: ProductEnhancement) =>
      API.put(APIRoutes.productEnhancements.byId(id), data),
    onSuccess: () => {
      closeFormDrawer();
      notify('success', 'Zmiany we wzbogaceniu produktu zostały zastosowane');
      queryClient.invalidateQueries('productEnhancements');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      notify('error', 'Wystąpił problem podczas edycji wzbogacenia produktu');
    },
  });

  const onSubmit = handleSubmit(async (data: ProductEnhancement) =>
    editProductEnhancement(data),
  );

  useEffect(() => {
    if (productEnhancement) reset({ ...DEFAULT_VALUES, ...productEnhancement });
  }, [productEnhancement, reset]);

  const isLoading = isFetching || isEditing;

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="product-enhancement-creator"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="product-enhancement-creator"
        />
      </DrawerFooter>
    </>
  );
}

export default ProductEnhancementEdit;
