import {
  FormControl,
  InputProps as ChakraInputProps,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { Tag, WithContext as ReactTags } from 'react-tag-input';
import { get, isEmpty } from 'lodash';
import { MultiValueInputWrapper } from 'components/Form/TagInput/style';

const Keys = {
  ENTER: 13,
  COMMA: 188,
};

type InputProps = ChakraInputProps & {
  name: string;
  label?: string | React.ReactNode;
  suggestions?: Tag[];
};

function TagInput({
  name,
  label,
  suggestions = [],
  isRequired,
  placeholder,
}: InputProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const errorMessage = get(errors, name)?.message as string;

  return (
    <FormControl isRequired={isRequired} isInvalid={Boolean(errorMessage)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <MultiValueInputWrapper>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => {
            const tags = value?.map((v: string) => ({ id: v, text: v }));
            return (
              <ReactTags
                allowDragDrop={false}
                tags={tags}
                handleAddition={(tag) => onChange([...value, tag.text])}
                handleDelete={(index) =>
                  onChange(
                    value?.filter(
                      (_tag: Tag, tagIndex: number) => index !== tagIndex,
                    ),
                  )
                }
                delimiters={[Keys.COMMA, Keys.ENTER]}
                placeholder={isEmpty(tags) && placeholder ? placeholder : ''}
                suggestions={suggestions}
              />
            );
          }}
        />
      </MultiValueInputWrapper>

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
}

TagInput.defaultProps = {
  label: '',
  suggestions: [],
};

export default TagInput;
