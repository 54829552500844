import { useEffect, useState } from 'react';
import { convertKeysToCamelCase, convertKeysToSnakeCase } from 'utils/helpers';
import { useAuth } from 'context/AuthContext';
import { API, APIRoutes } from 'api';

const CAMEL_CASE_ENDPOINTS = [
  APIRoutes.premiums.batchUpdate,
  APIRoutes.configurators.index(),
  APIRoutes.productBundles.index(),
];

const AxiosInterceptor = ({ children }: { children: any }) => {
  const { logout } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const requestInterceptor = API.interceptors.request.use((config) => {
      const { url = '', method = '' } = config;

      const isCamelCaseEndpoint = CAMEL_CASE_ENDPOINTS.some((endpoint) =>
        url.includes(endpoint),
      );
      if (['post', 'put'].includes(method) && isCamelCaseEndpoint) {
        return config;
      }

      const newConfig = { ...config };
      if (newConfig.headers['Content-Type'] === 'multipart/form-data')
        return newConfig;
      if (config.params) {
        newConfig.params = convertKeysToSnakeCase(config.params);
      }
      if (config.data) {
        newConfig.data = convertKeysToSnakeCase(config.data);
      }
      return newConfig;
    });

    const responseInterceptor = API.interceptors.response.use(
      (response) => {
        if (response.data) {
          response.data = convertKeysToCamelCase(response.data);
        }
        return response;
      },
      async (error) => {
        if (error.response.status === 401 || error.response.status === 403) {
          return logout();
        }
        return Promise.reject(convertKeysToCamelCase(error.response.data));
      },
    );

    setIsInitialized(true);

    return () => {
      API.interceptors.request.eject(requestInterceptor);
      API.interceptors.response.eject(responseInterceptor);
      setIsInitialized(false);
    };
  }, [logout]);

  return isInitialized && children;
};

export default AxiosInterceptor;
