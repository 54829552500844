import { Box, BoxProps, Image } from '@chakra-ui/react';

type ImagePreviewProps = BoxProps & {
  url?: string;
  imageHeight?: string | number;
};
function ImagePreview({ url, imageHeight, ...props }: ImagePreviewProps) {
  if (!url) return <Box {...props}>brak</Box>;

  return (
    <Image
      fit="scale-down"
      src={url}
      alt="image"
      bgColor="complementary.gray"
      h={imageHeight || 'inherit'}
    />
  );
}

ImagePreview.defaultProps = {
  url: '',
  imageHeight: '',
};
export default ImagePreview;
