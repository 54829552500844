/* eslint-disable @typescript-eslint/dot-notation */
import {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import { noop } from 'lodash';
import { useQueryClient } from 'react-query';
import {
  API,
  APIRoutes,
  destroySession,
  predicate,
  setAuthHeader,
  setStoreHeader,
} from 'api';
import {
  getAuthToken,
  getAuthStore,
  setAuthToken,
  setAuthStore,
} from 'utils/auth';

const AuthContextDefaultState: AuthContextType = {
  signIn: async () => {},
  logout: noop,
  isAuthenticated: false,
  isInitialized: false,
  store: 'default',
  changeStore: () => null,
};

const AuthContext = createContext(AuthContextDefaultState);

function AuthProvider({ children }: { children: ReactNode }) {
  const queryClient = useQueryClient();

  const [isInitialized, setIsInitialized] = useState(false);
  const [store, setStore] = useState(getAuthStore());
  const isAuthenticated = !!getAuthToken(store);

  const changeStore = useCallback(
    (newStore: string) => {
      queryClient.removeQueries({
        predicate,
      });
      setAuthStore(newStore);
      setStore(newStore);
    },
    [queryClient],
  );

  const logout = useCallback(async () => {
    destroySession();
    localStorage.clear();
    queryClient.removeQueries();
    setIsInitialized(false);
  }, [queryClient]);

  const signIn = useCallback<SignIn>(
    async (payload) => {
      if (payload) {
        const {
          data: {
            data: { token },
          },
        } = await API.post(APIRoutes.auth.signIn, payload);

        setAuthHeader(token);
        setAuthToken(token, store);
        setAuthStore(store);
        setIsInitialized(true);
      }
    },
    [store],
  );

  useEffect(() => {
    setIsInitialized(false);
    setStoreHeader(store);

    const token = getAuthToken(store);
    if (token) {
      setAuthHeader(token);
      setIsInitialized(true);
    }

    queryClient.invalidateQueries({
      predicate,
    });
  }, [queryClient, store]);

  const globalContextValue = useMemo(
    () => ({
      ...AuthContextDefaultState,
      signIn,
      logout,
      isAuthenticated,
      isInitialized,
      store,
      changeStore,
    }),
    [signIn, logout, isAuthenticated, isInitialized, store, changeStore],
  );

  return (
    <AuthContext.Provider value={globalContextValue}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
