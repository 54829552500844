import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Card = styled(Box)`
  background-color: #ffffff;
  padding: 24px 32px;
  border-radius: 8px;
`;

export default Card;
