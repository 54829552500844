import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME } from 'api';

const fetchConfigurators = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.configurators.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.total,
  };
};

const fetchConfigurator = async (id: string) => {
  const { data } = await API.get(APIRoutes.configurators.byId(id));
  return data;
};

const fetchAutocomplete = async (sku: string) => {
  const { data } = await API.get(APIRoutes.configurators.autocomplete(sku));
  return data;
};

const useGetConfigurators = (queryString: string) =>
  useQuery<ConfiguratorsResponse>(
    ['configurators', queryString],
    () => fetchConfigurators(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetConfigurator = (id: string) =>
  useQuery<ConfiguratorResponse>(['configurator', id], () =>
    fetchConfigurator(id),
  );

const useGetAutocomplete = (sku: string) =>
  useQuery<AutocompleteResponse>(
    ['configuratorAutocomplete', sku],
    () => fetchAutocomplete(sku),
    {
      enabled: false,
      cacheTime: 0,
    },
  );

export { useGetConfigurators, useGetConfigurator, useGetAutocomplete };
