import * as yup from 'yup';
import { ERROR_MESSAGES } from 'utils/form';

const BODY_SCHEMA = yup.object({
  sku: yup.string().required(ERROR_MESSAGES.required),
  arLink: yup.string(),
  threeDLink: yup.string(),
  changingRoomLink: yup.string(),
  webmLink: yup.string(),
  mp4Link: yup.string(),
});

const DEFAULT_VALUES = {
  sku: '',
  arLink: '',
  threeDLink: '',
  changingRoomLink: '',
  webmLink: '',
  mp4Link: '',
};

export { BODY_SCHEMA, DEFAULT_VALUES };
