import { Text } from '@chakra-ui/react';

function LoadingIndicator() {
  return (
    <Text marginBlockStart="40px" fontSize={24}>
      Ładowanie danych...
    </Text>
  );
}

export default LoadingIndicator;
