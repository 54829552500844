import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import useQueryParams from 'utils/useQueryParams';
import { PAGE, SKU, CATEGORY } from 'pages/Premiums/Listing/constants';
import FilterBox from 'components/FilterBox';
import TagInput from 'components/Form/TagInput';

function FilterBody() {
  const { search, setSearch } = useQueryParams();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      sku: search.get(SKU)?.split(',') || [],
      categoryId: search.get(CATEGORY)?.split(',') || [],
    },
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { sku, categoryId } = data;

    if (isEmpty(sku)) search.delete(SKU);
    else search.set(SKU, sku?.join(','));

    if (isEmpty(categoryId)) search.delete(CATEGORY);
    else search.set(CATEGORY, categoryId?.join(','));

    search.set(PAGE, '1');
    setSearch(search);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} id="filter-form">
        <FilterBox
          filterForm={
            <>
              <TagInput name={SKU} placeholder="SKU (oddzielone przecinkiem)" />
              <TagInput name={CATEGORY} placeholder="ID kategorii" />
            </>
          }
        />
      </form>
    </FormProvider>
  );
}

export default FilterBody;
