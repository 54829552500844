import * as yup from 'yup';
import { ERROR_MESSAGES, LIMITS } from 'utils/form';

const CREATE_SCHEMA = yup.object({
  email: yup
    .string()
    .required(ERROR_MESSAGES.required)
    .email(ERROR_MESSAGES.invalidEmail),
  password: yup
    .string()
    .required(ERROR_MESSAGES.required)
    .min(LIMITS.minPasswordLength, ERROR_MESSAGES.minPasswordLength),
});

const EDIT_SCHEMA = yup.object({
  email: yup.string().email(ERROR_MESSAGES.invalidEmail),
  password: yup
    .string()
    .min(LIMITS.minPasswordLength, ERROR_MESSAGES.minPasswordLength)
    .nullable()
    .transform((value) => value || null),
});

const DEFAULT_VALUES = {
  email: '',
  password: '',
};

export { CREATE_SCHEMA, EDIT_SCHEMA, DEFAULT_VALUES };
