import ReactSelect, {
  DropdownIndicatorProps,
  OptionProps,
  Props,
  StylesConfig,
  components,
} from 'react-select';
import { Flex } from '@chakra-ui/react';
import Check from 'components/Icons/Check';
import ChevronRight from 'components/Icons/ChevronRight';
import colors from 'theme/colors';

const styles: (withError?: boolean) => StylesConfig = (withError) => ({
  container: (provided) => ({ ...provided, width: '100%' }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    border: `1px solid ${
      withError ? colors.complementary.error : colors.complementary.outline
    }`,
    borderRadius: '8px',
    boxShadow: 'unset',
    backgroundColor: 'white',
    fontWeight: 400,
    fontSize: '14px',
    ':hover': {
      borderColor: colors.complementary.outline,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '12px 0px 12px 16px',
  }),
  input: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    width: '72px',
    justifyContent: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '5px',
    border: '1px solid',
    borderColor: colors.complementary.outline,
    boxShadow: 'unset',
    zIndex: 5,
    marginTop: '8px',
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 10,
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    color: 'inherit',
    backgroundColor: 'inherit',
    paddingRight: isSelected ? '24px' : 'inherit',
    ':hover': {
      backgroundColor: colors.complementary.outline,
    },
  }),
});

function DropdownIndicator({
  selectProps: { menuIsOpen },
}: DropdownIndicatorProps) {
  return (
    <ChevronRight
      sx={{
        transform: `rotate(${menuIsOpen ? -90 : 90}deg)`,
        transition: 'transform 0.2s',
      }}
    />
  );
}

function IconOption(props: OptionProps) {
  const { label, isSelected } = props;
  return (
    <components.Option {...props}>
      <Flex
        direction="row"
        w="100%"
        align="center"
        justify="space-between"
        fontWeight={isSelected ? 600 : 400}
      >
        {label}
        {isSelected && <Check />}
      </Flex>
    </components.Option>
  );
}

const customComponents = {
  DropdownIndicator,
  Option: IconOption,
  IndicatorSeparator: () => null,
};

interface SelectProps extends Props {
  instanceId: string | number;
  withError?: boolean;
}

function SingleSelect(props: SelectProps) {
  const { withError } = props;

  return (
    <ReactSelect
      components={customComponents}
      styles={styles(withError)}
      menuPortalTarget={document.body}
      {...props}
    />
  );
}

SingleSelect.defaultProps = {
  withError: false,
};

export default SingleSelect;
