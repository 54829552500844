import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import '@fontsource/jost';
import '@fontsource/jost/600.css';

import { AuthProvider } from 'context/AuthContext';
import AxiosInterceptor from 'context/AxiosInterceptor';
import customTheme from 'theme';
import { FormDrawerContextProvider } from 'context/FormDrawerContext';
import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      notifyOnChangeProps: ['data', 'error', 'isFetching'],
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ChakraProvider theme={customTheme}>
          <FormDrawerContextProvider>
            <AxiosInterceptor>
              <App />
            </AxiosInterceptor>
          </FormDrawerContextProvider>
        </ChakraProvider>
      </AuthProvider>
    </QueryClientProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
