import { format } from 'date-fns';
import isNil from 'lodash/isNil';

const datePrettier = (dateString: string | undefined): string => {
  if (isNil(dateString)) return '';
  const dt = new Date(dateString);
  const date = format(dt, 'dd.MM.yyyy');
  const time = format(dt, 'HH:mm');
  return `${date} ${time}`;
};

const datePrettierNoTime = (dateString: string | undefined): string => {
  if (isNil(dateString)) return '';
  const dt = new Date(dateString);
  const date = format(dt, 'dd.MM.yyyy');
  return date;
};

export { datePrettier, datePrettierNoTime };
