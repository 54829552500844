import { useQuery } from 'react-query';
import { API, APIRoutes, DEFAULT_STALE_TIME } from 'api';

const fetchProductEnhancements = async (queryString: string) => {
  const { data: response } = await API.get(
    APIRoutes.productEnhancements.index(queryString),
  );
  return {
    data: response?.data,
    totals: response?.meta?.total,
  };
};

const fetchProductEnhancement = async (id: string) => {
  const { data } = await API.get(APIRoutes.productEnhancements.byId(id));
  return data;
};

const useGetProductEnhancements = (queryString: string) =>
  useQuery<ProductEnhancementsResponse>(
    ['productEnhancements', queryString],
    () => fetchProductEnhancements(queryString),
    {
      staleTime: DEFAULT_STALE_TIME,
    },
  );

const useGetProductEnhancement = (id: string) =>
  useQuery<ProductEnhancementResponse>(['productEnhancement', id], () =>
    fetchProductEnhancement(id),
  );

export { useGetProductEnhancements, useGetProductEnhancement };
