import { Stack, Text } from '@chakra-ui/react';
import { ColumnDef } from '@tanstack/react-table';
import HeaderCell from 'components/Table/HeaderCell';
import { BUNDLE_TYPE_DICT } from 'pages/ProductBundles/Form/constants';
import { datePrettier } from 'utils/date';

function GetColumns(): ColumnDef<ProductBundle, string>[] {
  return [
    {
      header: 'Nazwa',
      accessorKey: 'name',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Typ',
      accessorKey: 'type',
      cell: ({
        row: {
          original: { lowProductsCount, type },
        },
      }) => (lowProductsCount ? 'Do uzupełnienia' : BUNDLE_TYPE_DICT[type]),
    },
    {
      header: 'SKU',
      cell: ({
        row: {
          original: { skus },
        },
      }) => (
        <Stack spacing={2}>
          {skus?.map((sku) => (
            <Text key={sku} as="span">
              {sku}
            </Text>
          ))}
        </Stack>
      ),
    },
    {
      header: () => (
        <HeaderCell sortKey="createdAt">Data utworzenia</HeaderCell>
      ),
      accessorKey: 'createdAt',
      cell: (info) => datePrettier(info.getValue()),
    },
    {
      header: 'Akcje',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
