import { Icon, IconProps } from '@chakra-ui/react';

function Menu(props: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_4_900"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4_900)">
        <path
          d="M16.65 13L11 7.35001L16.65 1.70001L22.3 7.35001L16.65 13ZM3 11V3.00001H11V11H3ZM13 21V13H21V21H13ZM3 21V13H11V21H3ZM5 9.00001H9V5.00001H5V9.00001ZM16.675 10.2L19.5 7.37501L16.675 4.55001L13.85 7.37501L16.675 10.2ZM15 19H19V15H15V19ZM5 19H9V15H5V19Z"
          fill="currentColor"
        />
      </g>
    </Icon>
  );
}

export default Menu;
