import Text from './text';
import Button from './button';
import Input from './input';
import FormLabel from './formLabel';
import FormError from './formError';
import Checkbox from './checkbox';
import Tag from './tag';
import Modal from './modal';
import Drawer from './drawer';
import Menu from './menu';
import Accordion from './accordion';
import Textarea from './textarea';

const components = {
  Text,
  Button,
  Input,
  FormLabel,
  FormError,
  Checkbox,
  Tag,
  Modal,
  Drawer,
  Menu,
  Accordion,
  Textarea,
};

export default components;
