/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import qs from 'query-string';
import { isEmpty } from 'lodash';
import { API, APIRoutes, BASE_API_URL, EXTENDED_STALE_TIME } from 'api';

const fetchProducts = async (queryString: string) => {
  const {
    data: { data },
  } = await API.get(`${BASE_API_URL}${APIRoutes.products(queryString)}`);

  return data;
};

const useGetProducts = (skus: string[]) =>
  useQuery<ProductsResponse>(
    ['products', skus],
    () => {
      const params = qs.stringify({ skus }, { arrayFormat: 'bracket' });
      return fetchProducts(params);
    },
    {
      enabled: !isEmpty(skus),
      staleTime: EXTENDED_STALE_TIME,
    },
  );

export { useGetProducts };
