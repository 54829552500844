import {
  Box,
  Button,
  ButtonProps,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { map } from 'lodash';
import { FOOTER_HEIGHT } from 'components/Footer';
import SystemInfosBox from 'components/SystemInfosBox';

type ListingActionButton = ButtonProps & {
  label: string;
  to?: string;
};

interface ListingLayoutProps {
  children: React.ReactNode;
  title: string;
  headerButtons: ListingActionButton[];
  filterBody?: React.ReactNode;
  showSystemInfos?: boolean;
}

function ListingLayout({
  title,
  headerButtons,
  children,
  filterBody,
  showSystemInfos,
}: ListingLayoutProps) {
  return (
    <Box w="100%" mb={FOOTER_HEIGHT}>
      <HStack justify="space-between" mb="52px">
        <Text variant="h1">{title}</Text>
        <HStack spacing="8px">
          {map(headerButtons, ({ label, ...buttonProps }, idx) => (
            <Button key={`button-${label}-${idx}`} {...buttonProps}>
              {label}
            </Button>
          ))}
        </HStack>
      </HStack>
      <Stack spacing={4}>
        {showSystemInfos && <SystemInfosBox />}
        {filterBody && filterBody}
        {children}
      </Stack>
    </Box>
  );
}

ListingLayout.defaultProps = {
  filterBody: null,
  showSystemInfos: false,
};

export default ListingLayout;
