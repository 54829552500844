import { ColumnDef } from '@tanstack/react-table';

function GetColumns(): ColumnDef<User, string>[] {
  return [
    {
      header: 'Id',
      cell: (info) => info.row.index + 1,
    },
    {
      header: 'Email',
      accessorKey: 'email',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Akcje',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
