import { ColumnDef } from '@tanstack/react-table';

function GetColumns(): ColumnDef<Configurator, string>[] {
  return [
    {
      header: 'Kod',
      accessorKey: 'code',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Tytuł',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'Akcje',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
