/* eslint-disable no-nested-ternary */
import { useCallback, memo, useState, useEffect } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import {
  Button,
  FormControl,
  Text,
  FormErrorMessage,
  Box,
  IconButton,
  VStack,
  Center,
} from '@chakra-ui/react';
import Delete from 'components/Icons/Delete';
import FileUploader from 'components/Icons/FileUploader';
import UploaderCheck from 'components/Icons/UploaderCheck';
import UploaderError from 'components/Icons/UploaderError';

interface DropzoneProps {
  name: string;
  onChange: (...event: any[]) => void;
  error: FieldError | undefined;
  title?: string;
  isRemovable?: boolean;
  acceptCsv: boolean;
  acceptImages: boolean;
}

function Dropzone({
  name,
  onChange,
  error,
  title,
  isRemovable,
  acceptCsv,
  acceptImages,
}: DropzoneProps) {
  const [file, setFile] = useState<File | null>(null);
  const { setValue, getValues } = useFormContext();
  const currentValue = getValues(name);

  const onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => void = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setValue(name, acceptedFiles[0], { shouldValidate: true });
    },
    [name, setValue],
  );

  useEffect(() => {
    if (currentValue?.url) setFile(null);
  }, [currentValue]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      ...(acceptCsv && {
        'text/csv': [],
      }),
      ...(acceptImages && {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
      }),
    },
  });

  return (
    <FormControl isInvalid={Boolean(error)} pb="10px" {...getRootProps()}>
      <Box
        border="1px dashed"
        borderRadius="6px"
        textAlign="center"
        borderColor={error ? 'complementary.error' : 'complementary.secondary'}
        minHeight={184}
        padding={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        backgroundColor={
          error ? 'complementary.white' : 'complementary.lightBlue'
        }
        position="relative"
      >
        {isDragActive && (
          <Center
            position="absolute"
            width="100%"
            height="100%"
            backdropFilter="auto"
            backdropBlur="4px"
            zIndex={99999}
            backgroundColor="rgba(0, 0, 0, 0.5)"
          >
            <Text fontSize="20px" fontWeight={600} color="complementary.white">
              Upuść plik tutaj
            </Text>
          </Center>
        )}
        <input {...getInputProps({ onChange })} />
        <Box mb={2}>
          {error ? (
            <UploaderError />
          ) : file ? (
            <UploaderCheck />
          ) : (
            <FileUploader />
          )}
        </Box>
        {file ? (
          <VStack spacing={2}>
            <Text>{file.name}</Text>
            {isRemovable && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setFile(null);
                  setValue(name, null, { shouldValidate: true });
                }}
                aria-label="delete file"
                variant="solidSecondary"
                icon={<Delete />}
                isRound
              />
            )}
          </VStack>
        ) : (
          <>
            <Text
              mb={1}
              dangerouslySetInnerHTML={{
                __html: title || 'Dodaj plik',
              }}
            />
            <Text
              fontSize="14px"
              lineHeight="20px"
              color="complementary.placeholder"
            >
              Przeciągnij i upuść lub dodaj z dysku
            </Text>
          </>
        )}
        <Button type="button" variant="link" mt={2}>
          {file ? 'Zmień' : 'Dodaj z dysku'}
        </Button>
      </Box>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

Dropzone.defaultProps = {
  title: null,
  isRemovable: false,
};

interface DropzoneFieldProps {
  name: string;
  title?: string;
  isRemovable?: boolean;
  acceptCsv?: boolean;
  acceptImages?: boolean;
}

function DropzoneField({
  name,
  title,
  isRemovable,
  acceptCsv = false,
  acceptImages = false,
}: DropzoneFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Dropzone
          name={name}
          onChange={(e: any) => onChange(e.target.files[0])}
          error={error}
          title={title}
          isRemovable={isRemovable}
          acceptCsv={acceptCsv}
          acceptImages={acceptImages}
        />
      )}
    />
  );
}
DropzoneField.defaultProps = {
  title: null,
  isRemovable: false,
  acceptCsv: false,
  acceptImages: false,
};
export default memo(DropzoneField);
