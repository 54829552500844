import { useCallback, useEffect } from 'react';
import { Stack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import Input from 'components/Form/Input';
import Select from 'components/Form/Select';
import { useGetAutocomplete } from 'api/configurators';

function ProductFormBody({
  prepareFieldName,
  index,
  availableTags,
}: ConfiguratorSectionFormBodyProps) {
  const fieldName = useCallback(
    (name: string) => prepareFieldName(`products[${index}].${name}`),
    [index, prepareFieldName],
  );

  const { control, setValue } = useFormContext();
  const parentSku = useWatch({
    control,
    name: fieldName('parentSku'),
  });

  const { data: { data: autocomplete } = {}, refetch } =
    useGetAutocomplete(parentSku);

  useEffect(() => {
    if (autocomplete) {
      setValue(fieldName('title'), autocomplete?.title);
      setValue(fieldName('sku'), autocomplete?.sku);
      setValue(fieldName('price'), autocomplete?.price?.value);
      setValue(fieldName('omnibusPrice'), autocomplete?.omnibusPrice?.value);
      setValue(fieldName('minimalPrice'), autocomplete?.minimalPrice?.value);
      setValue(fieldName('currency'), autocomplete?.price?.currency);
      setValue(fieldName('imageUrl'), autocomplete?.image);
    }
  }, [autocomplete, fieldName, setValue]);

  return (
    <Stack w="100%" spacing={4}>
      <Input name={fieldName('title')} label="Tytuł" />
      <Select
        name={fieldName('tag')}
        label="Tag (kod)"
        placeholder="Wybierz z listy"
        options={availableTags}
        isMulti
      />
      <Input name={fieldName('sku')} label="SKU" />
      <Input
        name={fieldName('parentSku')}
        label="SKU nadrzędne"
        onBlur={() => {
          if (parentSku) {
            refetch();
          }
        }}
      />
      <Input name={fieldName('price')} label="Cena (np. 199.99)" />
      <Input name={fieldName('omnibusPrice')} label="Cena Omnibus" />
      <Input name={fieldName('minimalPrice')} label="Cena minimalna" />
      <Input name={fieldName('currency')} label="Waluta (np. PLN)" />
      <Input name={fieldName('imageUrl')} label="URL obrazka" />
    </Stack>
  );
}

export default ProductFormBody;
