import { Icon, IconProps } from '@chakra-ui/react';

function UploaderCheck(props: IconProps) {
  return (
    <Icon
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="19.5" fill="white" stroke="#EBEDFB" />
      <mask
        id="mask0_426_2230"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="24"
      >
        <rect x="8" y="8" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_426_2230)">
        <path
          d="M17.5501 25.9996L11.8501 20.2996L13.2751 18.8746L17.5501 23.1496L26.7251 13.9746L28.1501 15.3996L17.5501 25.9996Z"
          fill="black"
        />
      </g>
    </Icon>
  );
}

export default UploaderCheck;
