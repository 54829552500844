import { DrawerBody, DrawerFooter, VStack } from '@chakra-ui/react';
import { FormProvider } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import FormBody from 'pages/ProductEnhancements/Form/components/FormBody';
import {
  BODY_SCHEMA,
  DEFAULT_VALUES,
} from 'pages/ProductEnhancements/Form/constants';
import { API, APIRoutes } from 'api';
import { useFormWithSchema } from 'utils/formHooks';
import FormButtons from 'components/FormButtons';
import { useFormDrawer } from 'context/FormDrawerContext';
import notify from 'utils/notify';
import transformErrors from 'utils/api';

function ProductEnhancementCreate() {
  const queryClient = useQueryClient();
  const { closeFormDrawer } = useFormDrawer();

  const methods = useFormWithSchema(BODY_SCHEMA, {
    mode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    formState: { isValid },
    setError,
  } = methods;

  const { mutate: createProductEnhancement, isLoading } = useMutation({
    mutationKey: 'productEnhancementsMutation',
    mutationFn: (data: ProductEnhancement) =>
      API.post(APIRoutes.productEnhancements.index(), data),
    onSuccess: () => {
      closeFormDrawer();
      notify(
        'success',
        'Gratulacje! Twoje dane zostały dodane pomyślnie. Nie widzisz wiersza w tabeli? Przełącz stronę by sprawdzić.',
      );
      queryClient.invalidateQueries('productEnhancements');
    },
    onError: ({ errors }) => {
      const transformedErrors = transformErrors(errors);
      Object.keys(transformedErrors).forEach((field: string) => {
        setError(field as never, {
          type: 'custom',
          message: transformedErrors[field],
        });
      });
      notify(
        'error',
        'Wystąpił problem podczas tworzenia wzbogacenia produktu',
      );
    },
  });

  const onSubmit = handleSubmit(async (data: ProductEnhancement) =>
    createProductEnhancement(data),
  );

  return (
    <>
      <DrawerBody>
        <FormProvider {...methods}>
          <VStack
            w="100%"
            h="100%"
            as="form"
            id="product-enhancement-creator"
            onSubmit={onSubmit}
            justify="space-between"
          >
            <FormBody isLoading={isLoading} />
          </VStack>
        </FormProvider>
      </DrawerBody>
      <DrawerFooter>
        <FormButtons
          onCancel={closeFormDrawer}
          isLoading={isLoading}
          isDisabled={!isValid}
          formId="product-enhancement-creator"
        />
      </DrawerFooter>
    </>
  );
}

export default ProductEnhancementCreate;
